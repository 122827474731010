<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.state.user_name }}
            </p>
            <span class="user-status">{{ $store.state.role_name }}</span>
          </div>
          <!-- <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/13-small.png')"
            class="badge-minimal"
            badge-variant="success"
          /> -->
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="gotToLink('/dashboard')"
        >
          <feather-icon size="16" icon="ArchiveIcon" class="mr-50" />
          <span>Dashboard</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="permission.user_manage"
          link-class="d-flex align-items-center"
          @click="gotToLink('/users')"
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>User Manage</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="permission.role_manage"
          link-class="d-flex align-items-center"
          @click="gotToLink('/roles')"
        >
          <feather-icon size="16" icon="CheckSquareIcon" class="mr-50" />
          <span>Role Manage</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      permission: {
        access: false,
        user_manage: false,
        role_manage: false,
      },
    };
  },
  mounted() {
    this.Permission();
  },
  methods: {
    gotToLink(link) {
      this.$router.push(link);
    },
    async logout() {
      const logout = await this.callApi("post", "/app/logout");
      if (logout.status == 200) {
        localStorage.removeItem("token");
        this.$store.dispatch("removeToken");
        this.$router.push({
          name: "login",
        });
      }
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "user.access") {
          this.permission.user_manage = true;
        }
        if (permission.slug == "role.access") {
          this.permission.role_manage = true;
        }
      });
    },
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
};
</script>
